import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/bjorn/git/entur-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "mobility-v2"
    }}>{`Mobility v2`}</h1>
    <p>{`Aggregation API for micro-mobility services.`}</p>
    <h2 {...{
      "id": "gbfs"
    }}>{`GBFS`}</h2>
    <p>{`Currently we offer data from a number of citybike and scooter operators in several Norwegian cities.`}</p>
    <p>{`All of our data is served in `}<a parentName="p" {...{
        "href": "https://github.com/NABSA/gbfs/blob/v2.2/gbfs.md"
      }}>{`version 2.2 of the General Bikeshare Feed Specification (GBFS)`}</a>{` format which is a standardized data feed for micro-mobility systems.`}</p>
    <h3 {...{
      "id": "list-the-gbfs-endpoint-for-all-operators"
    }}>{`List the GBFS endpoint for all operators`}</h3>
    <p>{`All currently implemented operators and their respective gbfs endpoints will be listed under the root endpoint.`}</p>
    <h4 {...{
      "id": "http-request"
    }}>{`HTTP Request`}</h4>
    <p><inlineCode parentName="p">{`GET https://api.entur.io/mobility/v2/gbfs`}</inlineCode></p>
    <h4 {...{
      "id": "example-response"
    }}>{`Example response`}</h4>
    <pre><code parentName="pre" {...{}}>{`{
    "systems": [
        {
            "id": "YOS:System:oslobysykkel",
            "url": "https://api.entur.io/mobility/v2/gbfs/oslobysykkel/gbfs"
        },
        {
            "id": "YBO:System:boltoslo",
            "url": "https://api.entur.io/mobility/v2/gbfs/boltoslo/gbfs"
        }
    ]
}

`}</code></pre>
    <h3 {...{
      "id": "gbfs-information-for-an-operator"
    }}>{`GBFS information for an operator`}</h3>
    <p>{`Get the different GBFS endpoints for a specific operator.`}</p>
    <h4 {...{
      "id": "http-request-1"
    }}>{`HTTP Request`}</h4>
    <p><inlineCode parentName="p">{`GET https://api.entur.io/mobility/v2/gbfs/{system}/gbfs`}</inlineCode></p>
    <p>{`Where `}<inlineCode parentName="p">{`{system}`}</inlineCode>{` is one of the last part of the system id, for instance `}<inlineCode parentName="p">{`oslobysykkel`}</inlineCode>{`.`}</p>
    <h4 {...{
      "id": "example-response-1"
    }}>{`Example response`}</h4>
    <pre><code parentName="pre" {...{}}>{`{
    "last_updated": 1610375175,
    "ttl": 15,
    "version": "2.2",
    "data": {
        "nb": {
            "feeds": [
                {
                    "name": "system_information",
                    "url": "https://api.entur.io/mobility/v2/gbfs/oslobysykkel/system_information"
                },
                {
                    "name": "station_information",
                    "url": "https://api.entur.io/mobility/v2/gbfs/oslobysykkel/station_information"
                },
                {
                    "name": "station_status",
                    "url": "https://api.entur.io/mobility/v2/gbfs/oslobysykkel/station_status"
                },
                {
                    "name": "system_pricing_plans",
                    "url": "https://api.entur.io/mobility/v2/gbfs/oslobysykkel/system_pricing_plans"
                },
                {
                    "name": "vehicle_types",
                    "url": "https://api.entur.io/mobility/v2/gbfs/oslobysykkel/vehicle_types"
                }
            ]
        }
    }
}
`}</code></pre>
    <h2 {...{
      "id": "graphql"
    }}>{`GraphQL`}</h2>
    <p>{`We also offer a client-centric GraphQL API as a way to aggregate data across all providers. It currently offers two
entrypoints to finding entities near a location: vehicles and stations.`}</p>
    <p>{`The GraphQL endpoint's URL is `}<a parentName="p" {...{
        "href": "https://api.entur.io/mobility/v2/graphql"
      }}>{`https://api.entur.io/mobility/v2/graphql`}</a></p>
    <p>{`An interactive IDE for exploring this API is available at `}<a parentName="p" {...{
        "href": "https://api.entur.io/graphql-explorer/mobility"
      }}>{`https://api.entur.io/graphql-explorer/mobility`}</a></p>
    <h2 {...{
      "id": "authentication"
    }}>{`Authentication`}</h2>
    <p>{`This API is open under NLOD licence, however, it is required that all consumers identify themselves by using the header ET-Client-Name. Entur will deploy strict rate-limiting policies on API-consumers who do not identify with a header and reserves the right to block unidentified consumers. The structure of ET-Client-Name should be: "company-application"`}</p>
    <p>{`Header examples:`}</p>
    <ul>
      <li parentName="ul">{`"brakar-journeyplanner"`}</li>
      <li parentName="ul">{`"fosen_utvikling-departureboard"`}</li>
      <li parentName="ul">{`"nor_way_bussekspress-nwy-app"`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      